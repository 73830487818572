import { React, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import { addPartnerWithApiAccess } from 'store/thunks/partnerThunks';

import ManagePartnerProfileModal from './ManagePartnerProfileModal';
import PartnerCredsDialog from './PartnerCredsDialog';

const AddPartnerButton = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModel, setShowModel] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [showPartnerCreds, setShowPartnerCreds] = useState(false);
  const [partnerCreds, setPartnerCreds] = useState(undefined);

  const handleOpenModal = () => setShowModel(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModel(false);
    }
  };

  const closePartnerCredsDialog = () => setShowPartnerCreds(false);

  const createPartnerProfile = (formData) => {
    setFormLoading(true);
    dispatch(addPartnerWithApiAccess(formData))
      .then((response) => {
        if (response) {
          setPartnerCreds(response);
          enqueueSnackbar('Partner profile created successfully', { variant: 'success' });
          setShowModel(false);
          setFormLoading(false);
          setShowPartnerCreds(true);
        }
      })
      .finally(() => setFormLoading(false));
  };

  return (
    <>
      <Button
        sx={{ height: 33, mr: 2 }}
        variant='contained'
        color='primary'
        onClick={handleOpenModal}
      >
        Add New
      </Button>

      {showModel && (
        <ManagePartnerProfileModal
          showModel={showModel}
          loading={formLoading}
          handleCloseModal={handleCloseModal}
          managePartnerProfile={createPartnerProfile}
        />
      )}

      {showPartnerCreds && (
        <PartnerCredsDialog
          onCloseModal={closePartnerCredsDialog}
          partnerCreds={partnerCreds}
          showModal={showPartnerCreds}
        />
      )}
    </>
  );
};

export default AddPartnerButton;
