import React from 'react';
import { Field } from 'react-final-form';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { composeValidations, validateRequired } from './formValidations';

const SelectField = ({
  name,
  id,
  label,
  children,
  required,
  disabled,
  validations,
  helperText,
  onChange,
  staticDisplayValue,
}) => {
  const validate = composeValidations([
    ...validations,
    ...(required ? [validateRequired('Required')] : []),
  ]);

  return (
    <Field
      id={id}
      name={name}
      validate={validate}
      render={({ input, meta }) => {
        // showError needs to be a boolean, material type check this value
        const showError = !!meta.error && !!meta.touched;
        const displayedHelperText = (!!meta.touched && meta.error) || helperText;

        const handleChange = (event) => {
          input.onChange(event.target.value);
          onChange(event);
        };

        const sortedChildren = React.Children.toArray(children).sort((firstValue, secondValue) => {
          const firstSortedValue = firstValue.props.children?.toString().toLowerCase() || '';
          const secondSortedValue = secondValue.props.children?.toString().toLowerCase() || '';
          return firstSortedValue.localeCompare(secondSortedValue);
        });

        return (
          <FormControl error={showError} fullWidth disabled={disabled} variant='standard'>
            <InputLabel
              shrink
              id={id}
              htmlFor={`${id}-input`}
              required={required}
              sx={{ fontSize: '1.2em' }}
            >
              {label}
            </InputLabel>

            <Select
              labelId={id}
              variant='outlined'
              sx={{
                'label + &': {
                  marginTop: '1.3em',
                },
                marginTop: '1.3em',
                boxSizing: 'border-box',
                borderRadius: 1,
                marginBottom: 0.25,
                padding: '4px 8px',
                '&:focus, &:focus&:invalid, &.Mui-focused': {
                  borderWidth: 1,
                },
                '.MuiSelect-select': {
                  padding: '5px 3px',
                  border: 'none',
                  '&.MuiInput-root': {
                    padding: '5px 7px',
                    border: 'none',
                    '&.Mui-focused': {
                      padding: '5px 7px',
                      border: 'none',
                    },
                  },
                },
              }}
              MenuProps={{
                sx: {
                  maxHeight: 400,
                },
              }}
              inputProps={{
                'aria-required': required,
                id: `${id}-input`,
                name,
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...{ ...input, ...(staticDisplayValue ? { value: staticDisplayValue } : {}) }} // intentional spreading, injecting final-form
              onChange={handleChange}
            >
              {sortedChildren}
            </Select>
            <FormHelperText>{displayedHelperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.bool])
  ).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  staticDisplayValue: PropTypes.string,
};

SelectField.defaultProps = {
  validations: [],
  required: false,
  disabled: false,
  helperText: '',
  onChange: noop,
  staticDisplayValue: undefined,
};

export default SelectField;
