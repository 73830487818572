/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

const MultipleSelectField = ({ name, id, label, selectedValue, onChange, options = [] }) => {
  const handleChange = (e) => onChange(e.target.value);

  return (
    <>
      <InputLabel shrink id={id} htmlFor={`${id}-input`} sx={{ fontSize: '1.2em' }}>
        {label}
      </InputLabel>

      <Select
        labelId={id}
        variant='outlined'
        fullWidth
        sx={{
          boxSizing: 'border-box',
          borderRadius: 1,
          marginBottom: 0.25,
          padding: '2px 6px',
          minWidth: 200,
          minHeight: 35,
          '&:focus, &:focus&:invalid, &.Mui-focused': {
            borderWidth: 1,
          },
          '.MuiSelect-select': {
            padding: '5px 3px',
            border: 'none',
            '&.MuiInput-root': {
              padding: '5px 7px',
              border: 'none',
              '&.Mui-focused': {
                padding: '5px 7px',
                border: 'none',
              },
            },
          },
        }}
        value={selectedValue}
        onChange={handleChange}
        inputProps={{
          id: `${id}-input`,
          name,
        }}
      >
        {[...options]
          .sort((firstValue, secondValue) => firstValue.localeCompare(secondValue))
          .map((key) => (
            <MenuItem key={key} value={key}>
              {key}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export default MultipleSelectField;

MultipleSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
};

MultipleSelectField.defaultProps = {
  options: [],
};
