/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getPatientsGql = gql`
  query getPatients(
    $limit: Int = 25
    $offset: Int = 0
    $filter: PatientsFilter
    $sortBy: PatientsSortBy
  ) {
    getPatients(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results {
        mpi
        firstName
        lastName
        gender
        dob
        createdTime
        status
        originalPartner {
          partnerName
        }
        onboardedAt
      }
    }
  }
`;

export const addPatientGql = gql`
  mutation addPatient($patientData: AddPatientInput!) {
    addPatient(input: $patientData) {
      mpi
      firstName
      lastName
    }
  }
`;

export const listPatientCommentsGql = gql`
  query listPatientComments(
    $limit: Int = 5
    $offset: Int = 0
    $mpi: UUID!
    $filter: CommentFilter
  ) {
    listPatientComments(mpi: $mpi, limit: $limit, offset: $offset, filter: $filter) {
      comment {
        commentId
        createdAt
        author {
          firstName
          lastName
          pomUserId
        }
        commentContent {
          message
        }
      }
    }
  }
`;

export const createPatientCommentGql = gql`
  mutation createPatientComment($mpi: UUID!, $message: String!, $contextId: String!) {
    createPatientComment(
      input: {
        mpi: $mpi
        content: { message: $message, contentType: TEXT }
        commentContext: { type: PATIENT_PROFILE, id: $contextId }
      }
    ) {
      comment {
        commentId
        createdAt
        author {
          firstName
          lastName
          pomUserId
        }
        commentContent {
          message
        }
      }
    }
  }
`;

export const deletePatientCommentGql = gql`
  mutation deletePatientComment($patientCommentId: UUID!) {
    deletePatientComment(patientCommentId: $patientCommentId)
  }
`;

export const getPatientGql = gql`
  query getPatient($mpi: UUID!) {
    getPatient(mpi: $mpi) {
      mpi: patientId
      patientIdentity {
        qs1PatientId
        invoiceServiceCustomerId
        crmId
        paymentServiceCustomerId
        partnerIdentities {
          partnerId
          identityValue
        }
      }
      onboardingStatusHistory {
        statusHistory
        currentStatus
        startTime
        endTime
      }
      patientDemographics {
        firstName
        lastName
        middleName
        dob
        gender
        status
        leadSource
        last4ssn
        originalPartner {
          partnerName
          partnerId
        }
        languages {
          isPrimary
          language
        }
        preferencesState {
          email
          phone
        }
      }
      patientContact {
        emails {
          email
          tags
        }
        phoneNumbers {
          phoneNumber
          tags
          isVerified
        }
      }
      lastUsedInsurance {
        patientInsuranceId
        planName
        planType
      }
    }
  }
`;

export const getPatientBalanceGql = gql`
  query getPatientBalance($mpi: UUID!) {
    patientBalance(mpi: $mpi) {
      outstandingBalance: chargeAmount
    }
  }
`;

export const editPatientGql = gql`
  mutation editPatient($patientData: EditPatientInput!) {
    editPatient(input: $patientData) {
      patientId
    }
  }
`;

export const addGuarantorGql = gql`
  mutation addGuarantor($guarantor: AddGuarantorInput!) {
    addGuarantor(input: $guarantor) {
      guarantorId
      firstName
      lastName
      middleName
      addressLine1
      addressLine2
      city
      state
      zip
      phoneNumbers {
        phoneNumber
        tags
      }
      email
      textsEnabled
      patientRelationship {
        relationship
      }
      isEmergencyContact
    }
  }
`;

export const getGuarantorsGql = gql`
  query getGuarantors($mpi: UUID!) {
    getGuarantors(mpi: $mpi) {
      guarantorId
      firstName
      lastName
      middleName
      addressLine1
      addressLine2
      city
      state
      zip
      phoneNumbers {
        phoneNumber
        tags
      }
      email
      textsEnabled
      patientRelationship {
        relationship
      }
      isEmergencyContact
    }
  }
`;

export const updateGuarantorGql = gql`
  mutation updateGuarantor($guarantor: UpdateGuarantorInput!) {
    updateGuarantor(input: $guarantor) {
      guarantorId
      firstName
      lastName
      middleName
      addressLine1
      addressLine2
      city
      state
      zip
      phoneNumbers {
        phoneNumber
        tags
      }
      email
      textsEnabled
      patientRelationship {
        relationship
      }
      isEmergencyContact
    }
  }
`;

export const deleteGuarantorGql = gql`
  mutation deleteGuarantor($guarantorId: UUID!, $mpi: UUID!) {
    deleteGuarantor(guarantorId: $guarantorId, mpi: $mpi)
  }
`;

export const addPatientMedicationGql = gql`
  mutation addPatientMedication($medicationData: AddPatientMedicationInput!) {
    addPatientMedication(input: $medicationData) {
      patientMedicationId
      name
      ndc
      dawCode
      strength
      form
      quantity
      instructions
      prescriber {
        name
      }
      pharmacy {
        pharmacyName
        pharmacyPhoneNumber
      }
    }
  }
`;

export const updatePatientMedicationGql = gql`
  mutation updatePatientMedication($medication: UpdatePatientMedicationInput!) {
    updatePatientMedication(input: $medication) {
      mpi
      patientMedicationId
      name
      ndc
      dawCode
      strength
      form
      quantity
      instructions
      prescriber {
        name
      }
      pharmacy {
        pharmacyName
        pharmacyPhoneNumber
      }
    }
  }
`;

export const deletePatientMedicationGql = gql`
  mutation deletePatientMedication($patientMedicationId: ID!, $mpi: UUID!) {
    deletePatientMedication(patientMedicationId: $patientMedicationId, mpi: $mpi)
  }
`;

export const listPatientMedicationsGql = gql`
  query listPatientMedications($mpi: UUID!) {
    listPatientMedications(mpi: $mpi) {
      results {
        patientMedicationId
        name
        ndc
        dawCode
        strength
        form
        quantity
        instructions
        prescriber {
          name
        }
        pharmacy {
          pharmacyName
          pharmacyPhoneNumber
        }
      }
    }
  }
`;

export const getPatientMedicalHistoryGql = gql`
  query getPatientMedicalHistory($mpi: UUID!) {
    getPatientMedicalHistory(mpi: $mpi) {
      medicalHistory {
        allergies: allergy {
          allergyType
          description
          patientAllergyId
        }
        surgeries: surgery {
          surgeryName
          dateOfSurgery
          description
          patientSurgeryId
        }
        medicalConditions: medicalCondition {
          medicalConditionName
          ICDCode
          description
          patientMedicalConditionId
        }
      }
    }
  }
`;

export const addPatientMedicalHistoryGql = gql`
  mutation addPatientMedicalHistory($input: PatientMedicalHistoryInput!) {
    addPatientMedicalHistory(input: $input) {
      medicalHistory {
        allergies: allergy {
          allergyType
          description
          patientAllergyId
        }
        surgeries: surgery {
          surgeryName
          dateOfSurgery
          description
          patientSurgeryId
        }
        medicalConditions: medicalCondition {
          medicalConditionName
          ICDCode
          description
          patientMedicalConditionId
        }
      }
    }
  }
`;

export const getAuthorizationsGql = gql`
  query getAuthorizations($mpi: UUID!) {
    getAuthorizations(mpi: $mpi) {
      authorization {
        permissionToTransfer
        mandatoryDisclosure
        childSafetyWaiver
        careboxAuthorization
        textingAuthorization
        authTimestamp
        auth {
          by
          id
          firstName
          lastName
          middleName
        }
      }
    }
  }
`;

export const updatePatientAuthorizationsGql = gql`
  mutation updatePatientAuthorizations($mpi: UUID!, $authorization: AuthorizationInput!) {
    updatePatientAuthorizations(input: { mpi: $mpi, authorization: $authorization }) {
      authorization {
        permissionToTransfer
        mandatoryDisclosure
        childSafetyWaiver
        careboxAuthorization
        textingAuthorization
        authTimestamp
        auth {
          by
          id
          firstName
          lastName
          middleName
        }
      }
    }
  }
`;

export const addPatientInsuranceGql = gql`
  mutation addPatientInsurance($insurance: AddPatientInsuranceInput!) {
    addPatientInsurance(input: $insurance) {
      patientInsuranceId
      planName
      priority
      verified
      verification {
        verifierName
        verifierUserId
        verificationModifiedAt
      }
      bin
      pcn
      ssn
      groupNumber
      insurancePlanId
      medicareNumber
      modifiedTimestamp
      isActive
      planType
    }
  }
`;

export const getPatientInsurancesGql = gql`
  query getPatientInsurances($mpi: UUID!) {
    getPatientInsurances(mpi: $mpi) {
      patientInsuranceId
      planName
      priority
      verified
      verification {
        verifierName
        verifierUserId
        verificationModifiedAt
      }
      bin
      pcn
      ssn
      groupNumber
      insurancePlanId
      medicareNumber
      modifiedTimestamp
      isActive
      planType
    }
  }
`;

export const onboardPatientGql = gql`
  mutation onboardPatient($onboardPatientInput: OnboardPatientInput!) {
    onboardPatient(input: $onboardPatientInput) {
      authorization {
        authorization {
          permissionToTransfer
          mandatoryDisclosure
          childSafetyWaiver
          careboxAuthorization
          textingAuthorization
          authTimestamp
          auth {
            by
            id
            firstName
            lastName
            middleName
          }
        }
      }
      patient {
        patientDemographics {
          status
          mpi
        }
      }
    }
  }
`;

export const addPatientProviderGql = gql`
  mutation addPatientProvider($provider: AddPatientProviderInput!) {
    addPatientProvider(input: $provider) {
      patientProviderId
      firstName
      lastName
      addressLine1
      addressLine2
      city
      state
      zip
      phone
      fax
      npi
      email
      isActive
    }
  }
`;

export const getPatientProvidersGql = gql`
  query getPatientProviders($mpi: UUID!) {
    getPatientProviders(mpi: $mpi) {
      patientProviderId
      firstName
      lastName
      addressLine1
      addressLine2
      city
      state
      zip
      phone
      fax
      npi
      email
      isActive
      verificationStatus
    }
  }
`;

export const updatePatientProviderGql = gql`
  mutation updatePatientProvider($provider: UpdatePatientProviderInput!) {
    updatePatientProvider(input: $provider) {
      patientProviderId
      firstName
      lastName
      addressLine1
      addressLine2
      city
      state
      zip
      phone
      fax
      npi
      email
      isActive
    }
  }
`;

export const deactivatePatientProviderGql = gql`
  mutation deactivatePatientProvider($patientProviderId: ID!) {
    deactivatePatientProvider(patientProviderId: $patientProviderId)
  }
`;

export const updatePatientInsuranceGql = gql`
  mutation updatePatientInsurance($insurance: UpdatePatientInsuranceInput!) {
    updatePatientInsurance(input: $insurance) {
      patientInsuranceId
      planName
      priority
      verified
      verification {
        verifierName
        verifierUserId
        verificationModifiedAt
      }
      bin
      pcn
      ssn
      groupNumber
      insurancePlanId
      medicareNumber
      modifiedTimestamp
      isActive
      planType
    }
  }
`;

export const deactivateInsuranceGql = gql`
  mutation deactivateInsurance($patientInsuranceId: ID!) {
    deactivateInsurance(patientInsuranceId: $patientInsuranceId)
  }
`;

export const runTestClaimGql = gql`
  mutation runTestClaim($input: RunTestClaimInput!) {
    runTestClaim(input: $input) {
      testProfileId
      testClaimSubmissionId
    }
  }
`;

export const saveTestClaimResultGql = gql`
  mutation saveTestClaimResult($input: SaveTestClaimResultInput!) {
    saveTestClaimResult(input: $input) {
      patientDemographics {
        status
      }
      systemIds: patientIdentity {
        qs1PatientId
        invoiceServiceCustomerId
        crmId
        paymentServiceCustomerId
      }
      lastUsedInsurance: patientInsurance {
        patientInsuranceId
        planName
        priority
        verified
        verification {
          verifierName
          verifierUserId
          verificationModifiedAt
        }
        bin
        pcn
        ssn
        groupNumber
        insurancePlanId
        medicareNumber
        modifiedTimestamp
        isActive
        planType
      }
    }
  }
`;

export const getCurrentTestClaimIdentityGql = gql`
  query getCurrentTestClaimIdentity($mpi: UUID!) {
    getCurrentTestClaimIdentity(mpi: $mpi) {
      testProfileId
      testClaimSubmissionId
    }
  }
`;

export const addPatientPharmacyGql = gql`
  mutation addPatientPharmacy($pharmacy: AddPatientPharmacyInput!) {
    addPatientPharmacy(input: $pharmacy) {
      patientPharmacyId
      name: pharmacyName
      phoneNumber: pharmacyPhoneNumber
      fax
      npi
      addressLine1
      addressLine2
      city
      state
      zip
    }
  }
`;

export const getPatientPharmaciesGql = gql`
  query getPatientPharmacies($mpi: UUID!) {
    getPatientPharmacies(mpi: $mpi) {
      patientPharmacyId
      name: pharmacyName
      phoneNumber: pharmacyPhoneNumber
      fax
      npi
      addressLine1
      addressLine2
      city
      state
      zip
    }
  }
`;

export const updatePatientPharmacyGql = gql`
  mutation updatePatientPharmacy($pharmacy: UpdatePatientPharmacyInput!) {
    updatePatientPharmacy(input: $pharmacy) {
      patientPharmacyId
      name: pharmacyName
      phoneNumber: pharmacyPhoneNumber
      fax
      npi
      addressLine1
      addressLine2
      city
      state
      zip
    }
  }
`;

export const deletePatientPharmacyGql = gql`
  mutation deletePatientPharmacy($patientPharmacyId: ID!) {
    deletePatientPharmacy(patientPharmacyId: $patientPharmacyId)
  }
`;

export const listPatientInvoicesGql = gql`
  query listPatientInvoices(
    $mpi: UUID!
    $offset: Int! = 0
    $limit: Int! = 10
    $sortBy: InvoiceSortBy
  ) {
    listPatientInvoices(patientId: $mpi, offset: $offset, limit: $limit, sortBy: $sortBy) {
      count
      invoices: results {
        invoiceId: externalInvoiceId
        invoiceStatus: status
        invoiceDate
        dueDate
        invoiceNumber
        totalAmount
        invoiceFileKey
      }
    }
  }
`;

export const getDownloadUrlForInvoiceKeyGql = gql`
  query getDownloadUrlForInvoiceKey(
    $invoiceFileKey: String!
    $invoiceServiceCustomerId: String!
    $invoiceId: String!
    $mpi: UUID!
  ) {
    getDownloadUrlForInvoiceKey(
      invoiceKey: $invoiceFileKey
      invoiceServiceCustomerId: $invoiceServiceCustomerId
      invoiceId: $invoiceId
      patientId: $mpi
    )
  }
`;

export const addPatientPartnersGql = gql`
  mutation addPatientPartners($mpi: UUID!, $partners: [PatientPartnerInput!]!) {
    addPatientPartners(input: { mpi: $mpi, partners: $partners }) {
      partner {
        partnerName
        partnerUuid
      }
    }
  }
`;

export const setPartnerExternalIdGql = gql`
  mutation setPartnerExternalId($partnerId: ID!, $mpi: UUID!, $identityValue: String!) {
    setPartnerExternalId(partnerUUID: $partnerId, mpi: $mpi, identityValue: $identityValue) {
      partnerId
      identityValue
    }
  }
`;

export const deletePatientPartnerGql = gql`
  mutation deletePatientPartner($mpi: UUID!, $partnerUUID: UUID!) {
    deletePatientPartner(mpi: $mpi, partnerUUID: $partnerUUID)
  }
`;

export const listPatientRequestsGql = gql`
  query listPatientRequests($mpi: UUID!) {
    listPatientRequests(mpi: $mpi) {
      requestId
      createdAt
      status
      partnerAccountId
      requestLineItems {
        status
        requestLineItemId
        needsByDate
        qs1RxNumber
        taggedForReview
        reviewReason
        itemType
        item {
          ... on TransferRequest {
            patientMedication {
              name
              ndc
              strength
              form
              quantity
              instructions
              dawCode
            }
          }
        }
      }
    }
  }
`;

export const listPatientPrescriptionsGql = gql`
  query listPatientPrescriptions(
    $mpi: UUID!
    $forceRefresh: Boolean! = false
    $offset: Int! = 0
    $limit: Int! = 0
  ) {
    listPatientPrescriptions(
      mpi: $mpi
      forceRefresh: $forceRefresh
      offset: $offset
      limit: $limit
    ) {
      results {
        rxNumber
        prescriber
        drug
        cycleFill
        dispensedQuantity
        primaryPlan
        hoa
        refillsRemaining
        lastFillDate
        sig
        prescriberPhone
        writtenDate
        expirationDate
        prescriptionUUID
        isActive
        autofill
        prescriptionType
        quantityPrescribed
        refillsAuthorized
        daw
        daysSupply
        strength
        rxNotes
        drugUnit
        orderId
        dateOrdered
        form
        documentId
        request {
          medication {
            instructions
            prescriber {
              addressLine1
              addressLine2
              city
              state
              zip
              phone
              fax
            }
          }
        }
        practice {
          practiceId
          practiceName
          prescriberName
          electronicallySignedBy
          supervisor
          phone
          fax
          prescriberNpi
          prescriberDea
          orderingAgent
          line1
          line2
          city
          state
          zip
          verificationStatus
        }
        prescriptionOverride {
          prescriptionUUID
          drugProfile {
            drugName
            strength
            unit
            genericDrugName
            manufacturer
            ndc
            form
          }
          sig {
            sigText
          }
          prescriptionDetails {
            prescriberName
            refills
            quantity
          }
          patientId
        }
        originatingPharmacy {
          ...PharmacyFields
        }
        transferPharmacies {
          ...PharmacyFields
        }
      }
      cacheRefreshed
    }
  }

  fragment PharmacyFields on Pharmacy {
    pharmacyAddress {
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
    }
    phoneNumber
    dea
    name
    npi
    pharmacistName
    transferDate
  }
`;

export const listFailedAdjudicationsGql = gql`
  query listFailedAdjudications(
    $limit: Int
    $offSet: Int
    $filter: AdjudicationFailedPrescriptionFilter
    $sortBy: FailedAdjudicationSortBy
  ) {
    listAdjudicationRejectedPrescriptions(
      limit: $limit
      offSet: $offSet
      filter: $filter
      sortBy: $sortBy
    ) {
      count
      results: result {
        patientName
        patientId
        adjudicatedTime
        adjudicationStatus
        patientQS1Id
        rxNumber
        prescriptionId
        prescriptionLastFillDate
        hasPinnedNotes
        rxRequestId
      }
    }
  }
`;

export const updateRefillAdjudicationStateGql = gql`
  mutation updateRefillAdjudicationState($rxRequestId: UUID!, $state: AdjudicationProcessState!) {
    updateRefillAdjudicationState(rxRequestId: $rxRequestId, state: $state)
  }
`;

export const getPatientAddressesGql = gql`
  query getPatientAddresses($mpi: UUID!) {
    getPatientAddresses(mpi: $mpi) {
      tags
      patientAddressId
      createdTimestamp
      nameOnAddress
      address {
        addressLine1: line1
        addressLine2: line2
        city
        state
        zip
      }
    }
  }
`;

export const addPatientAddressGql = gql`
  mutation addPatientAddress($input: AddPatientAddressInput!) {
    addPatientAddress(input: $input) {
      __typename
      ... on PatientAddress {
        address {
          addressLine1: line1
          addressLine2: line2
          city
          state
          zip
        }
        nameOnAddress
        tags
        patientAddressId
      }
      ... on Addresses {
        addresses {
          line1
          line2
          city
          state
          zip
        }
      }
    }
  }
`;

export const deletePatientAddressGql = gql`
  mutation deletePatientAddress($mpi: UUID!, $patientAddressId: UUID!) {
    deletePatientAddress(mpi: $mpi, patientAddressId: $patientAddressId)
  }
`;

export const updatePatientAddressGql = gql`
  mutation updatePatientAddress($input: UpdatePatientAddressInput!) {
    updatePatientAddress(input: $input) {
      tags
      patientAddressId
      createdTimestamp
      nameOnAddress
      address {
        addressLine1: line1
        addressLine2: line2
        city
        state
        zip
      }
    }
  }
`;

export const listPatientOrdersGql = gql`
  query listPatientOrders($mpi: UUID!) {
    listPatientOrders(mpi: $mpi) {
      results: result {
        orderId
        requestId
        fulfillingPharmacyId
        status: orderStatus
        requestNeedsByDate
        orderCost
        orderType
        isProcessedExternal
        partner {
          partnerName
        }
        partnerAccountId
        shippingAddress {
          line1
          line2
          city
          state
          zip
        }
        lineItems {
          itemName
          needsByDate
          requestedDate
          itemType
          lineItemId
          hasPinnedNote
          rxNumber
          strength
          form
          quantity
          lineItemStatus
          lastUpdated
          cost
          prescriptionId
          taggedForReview
          isPrinted
          documentId
        }
      }
    }
  }
`;

export const createLineItemNoteGql = gql`
  mutation createPatientNote(
    $mpi: UUID!
    $source: CommentSource
    $content: CommentContentInput!
    $commentContext: CommentContextInput!
  ) {
    createPatientNote(
      input: { mpi: $mpi, content: $content, commentContext: $commentContext, source: $source }
    ) {
      comment {
        commentId
        createdAt
        author {
          firstName
          lastName
          pomUserId
        }
        commentContent {
          message
        }
        currentState {
          state
        }
        commentContext {
          id
          type
        }
      }
    }
  }
`;

export const listLineItemNotesGql = gql`
  query listPatientNotes(
    $mpi: UUID!
    $contextType: NoteContextType
    $filter: NoteFilter
    $offset: Int!
    $limit: Int!
  ) {
    listPatientNotes(
      mpi: $mpi
      contextType: $contextType
      limit: $limit
      offset: $offset
      filter: $filter
    ) {
      comment {
        commentId
        author {
          firstName
          lastName
          pomUserId
        }
        commentContext {
          id
        }
        commentContent {
          message
        }
        currentState {
          state
        }
        createdAt
      }
    }
  }
`;

export const noteActionGql = gql`
  mutation noteAction($noteId: UUID!, $action: NoteAction!) {
    noteAction(noteId: $noteId, action: $action)
  }
`;

export const listPatientOrderShipmentsGql = gql`
  query listPatientOrderShipments($mpi: UUID!, $filter: OrderShipmentFilter) {
    listPatientOrderShipments(
      mpi: $mpi
      filter: $filter
      sortBy: { sortKey: shipmentDate, sortOrder: DESC }
    ) {
      orderShipmentId
      spOrderId
      shipmentSpType
      shipmentStatus
      shipmentData
      orderIds
      shipmentCreatedDate
      shipmentUpdatedDate
      lineItems {
        itemName
        needsByDate
        requestedDate
        itemType
        lineItemId
        lineItemStatus
        rxNumber
        strength
        form
        quantity
        lastUpdated
        hasPinnedNote
        autoFill
        cost
      }
    }
  }
`;

export const getPatientUsersGql = gql`
  query getPatientUsers($mpi: UUID!) {
    getPatientUsers(mpi: $mpi) {
      mpi
      peUser {
        userId
        firstName
        lastName
        emailAddress
      }
    }
  }
`;

export const triggerForgotPasswordGql = gql`
  mutation triggerPasswordResetForPEUser($peUserId: UUID!) {
    triggerPasswordResetForPEUser(peUserId: $peUserId)
  }
`;

export const editPatientPrescriptionGql = gql`
  mutation editPatientPrescription(
    $prescriptionId: UUID!
    $action: PrescriptionState
    $autoFill: Boolean
  ) {
    editPatientPrescription(prescriptionId: $prescriptionId, action: $action, autoFill: $autoFill) {
      isActive
      autofill
    }
  }
`;

export const searchQS1ProfileGql = gql`
  query searchQs1Profile($firstName: String!, $lastName: String!, $dob: String!) {
    searchQs1Profile(firstName: $firstName, lastName: $lastName, dob: $dob) {
      qs1Id
      firstName
      lastName
      dob
    }
  }
`;

export const associatePatientWithExternalProfileGql = gql`
  mutation associatePatientWithExternalProfile(
    $mpi: UUID!
    $externalProfile: ExternalProfileInput!
  ) {
    associatePatientWithExternalProfile(mpi: $mpi, externalProfile: $externalProfile) {
      qs1Id
      crmId
      invoiceId
      paymentServiceId
    }
  }
`;

export const editOrderShipmentGql = gql`
  mutation editOrderShipment(
    $orderShipmentId: String!
    $trackingNumber: String!
    $carrierCode: String!
    $spOrderId: String!
  ) {
    editOrderShipment(
      orderShipmentId: $orderShipmentId
      trackingNumber: $trackingNumber
      carrierCode: $carrierCode
      spOrderId: $spOrderId
    ) {
      shipmentData
      spOrderId
      shipmentStatus
      shipmentUpdatedDate
      orderShipmentId
    }
  }
`;
