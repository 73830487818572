/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { debounce, find, isEmpty, map, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';

import { listDrugProfilesByDrugName } from 'store/thunks/drugThunks';
import { resolveDrugProfile } from 'store/thunks/workQueueThunks';

import AutoSuggest from 'common/forms/AutoSuggest';
import DrugProfileForm from 'modules/DrugManagement/components/DrugProfileForm';
import { PrescriptionReviewStatus } from 'enums/prescription.enum';
import { PrescriptionReviewPayloadContext } from 'common/hooks/usePrescriptionReviewPayloadContext';

const ResolveDrugOption = {
  CREATE_NEW_DRUG: 'CREATE_NEW_DRUG',
  ASSOCIATE_WITH_DRUG: 'ASSOCIATE_WITH_DRUG',
};

const tableCellStye = { borderBottom: 'none', p: 1 };

const ResolveDrugProfileModal = ({
  open,
  onClose,
  drugData,
  prescriptionId,
  isEditLabel,
  patientId,
  reviewType,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [drugs, setDrugs] = useState([]);
  const [formLoading, setFormLoading] = useState(false);
  const [drugLoading, setDrugLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasOneTimeAssociation, setOneTimeAssociation] = useState(false);
  const [selectedDrugProfile, setSelectedDrugProfile] = useState('');
  const [reviewOption, setReviewOption] = useState(ResolveDrugOption.ASSOCIATE_WITH_DRUG);
  const { payload: prescriptionReviewPayload, setPayload } = useContext(
    PrescriptionReviewPayloadContext
  );

  const handleChangeRadio = (event) => setReviewOption(event.target.value);
  const handleOneTimeAssociation = () => setOneTimeAssociation(!hasOneTimeAssociation);

  const fetchDrugs = debounce((searchText) => {
    setDrugLoading(true);
    dispatch(listDrugProfilesByDrugName(searchText))
      .then((drugProfiles) => {
        setDrugs(drugProfiles);
      })
      .finally(() => setDrugLoading(false));
  }, 500);

  const handleResolveDrugProfile = (formData) => {
    if (reviewType === PrescriptionReviewStatus.DRUG_PROFILE_REVIEW) {
      setFormLoading(true);

      const payload =
        reviewOption === ResolveDrugOption.CREATE_NEW_DRUG
          ? { input: { ...omit(formData, 'drug'), isActive: true }, prescriptionId }
          : {
              input: {
                ...omit(formData.searchExistingDrug, ['id', 'label', 'strength']),
                hasOneTimeAssociation,
                isDrugProfileResolve: true,
                strength: drugData?.strength,
                associatedPrescriptionDescriptions: [drugData.drug],
              },
              prescriptionId,
            };

      dispatch(resolveDrugProfile(payload))
        .then((response) => {
          if (response) {
            enqueueSnackbar('Drug profile resolved successfully', { variant: 'success' });
            setFormLoading(false);
            onClose();
          }
        })
        .finally(() => setFormLoading(false));
    } else {
      const selectedDrugDetails = find(drugs, { drugProfileId: selectedDrugProfile });
      const overrideDrugProfilePayload = !isEmpty(prescriptionReviewPayload)
        ? {
            ...prescriptionReviewPayload,
            drugProfile: selectedDrugDetails,
          }
        : {
            patientId,
            drugProfile: selectedDrugDetails,
          };

      setPayload(overrideDrugProfilePayload);

      onClose();
    }
  };

  useEffect(() => {
    if (
      (reviewOption === ResolveDrugOption.ASSOCIATE_WITH_DRUG || isEditLabel) &&
      !isEmpty(selectedDrugProfile)
    ) {
      setIsFormValid(true);
    }
  }, [selectedDrugProfile, isEditLabel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reviewOption === ResolveDrugOption.CREATE_NEW_DRUG) {
      setIsFormValid(true);
      setSelectedDrugProfile('');
      return;
    }
    setIsFormValid(false);
    setSelectedDrugProfile('');
  }, [reviewOption]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        {reviewType === PrescriptionReviewStatus.DRUG_PROFILE_REVIEW
          ? 'Resolve Drug Profile'
          : 'Edit Drug profile'}
      </DialogTitle>
      <Grid sx={{ px: 3 }}>
        <Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableCellStye, p: 0 }}>
                  <Typography sx={{ fontWeight: 600 }}>Drug</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <DrugInfo fieldKey='Description' fieldValue={drugData.drug} />
              <DrugInfo fieldKey='Strength' fieldValue={drugData.strength} />
              <DrugInfo fieldKey='Unit' fieldValue={drugData.unit} />
              <DrugInfo fieldKey='Form' fieldValue={drugData.form} />
            </TableBody>
          </Table>
        </Grid>

        {reviewType === PrescriptionReviewStatus.DRUG_PROFILE_REVIEW && (
          <Grid container direction='row' sx={{ p: 1, pl: 0 }}>
            <FormControl>
              <RadioGroup
                aria-labelledby='Review-Drug-option-radioSelect-group'
                value={reviewOption}
                onChange={handleChangeRadio}
                row
              >
                <FormControlLabel
                  value={ResolveDrugOption.ASSOCIATE_WITH_DRUG}
                  control={<Radio />}
                  label='Select from Existing Profile'
                />
                <FormControlLabel
                  value={ResolveDrugOption.CREATE_NEW_DRUG}
                  control={<Radio />}
                  label='Create new'
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        {reviewOption === ResolveDrugOption.CREATE_NEW_DRUG && (
          <Typography variant='h6' sx={{ py: 2 }}>
            Create Drug Profile
            <Divider />
          </Typography>
        )}
      </Grid>

      <Form
        key={reviewOption}
        onSubmit={handleResolveDrugProfile}
        initialValues={{ ...drugData, drugName: drugData.drug }}
        render={({ handleSubmit, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: 600 }}>
              {reviewOption === ResolveDrugOption.ASSOCIATE_WITH_DRUG && (
                <AutoSuggest
                  id='Review-Existing-Drug-Input'
                  name='searchExistingDrug'
                  label='Search Existing'
                  loading={drugLoading}
                  options={[
                    ...map(drugs, (drugDetail) => ({
                      ...drugDetail,
                      label: drugDetail.drugName,
                      id: drugDetail.drugProfileId,
                    })),
                  ]}
                  fetchOptions={fetchDrugs}
                  selected={selectedDrugProfile}
                  handleSelect={setSelectedDrugProfile}
                />
              )}

              {reviewOption === ResolveDrugOption.CREATE_NEW_DRUG && (
                <DrugProfileForm disableIsActive />
              )}
            </DialogContent>

            <DialogActions>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  {reviewOption === ResolveDrugOption.ASSOCIATE_WITH_DRUG ? (
                    <Grid container alignItems='center'>
                      <FormControlLabel
                        label='One Time Association'
                        control={<Checkbox onChange={handleOneTimeAssociation} />}
                        sx={{ mr: 1 }}
                      />
                      <Tooltip
                        title='This association will apply only to the current case.'
                        arrow
                        color='secondary'
                        placement='top-start'
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid item>
                  <Button sx={{ mr: 1 }} variant='outlined' color='secondary' onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={formLoading}
                    disabled={invalid || !isFormValid || drugLoading}
                    variant='contained'
                    type='submit'
                  >
                    Confirm
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default ResolveDrugProfileModal;

const DrugInfo = ({ fieldKey, fieldValue }) => (
  <TableRow>
    <TableCell sx={tableCellStye}>{fieldKey}:</TableCell>
    <TableCell sx={tableCellStye}>{fieldValue} </TableCell>
  </TableRow>
);

DrugInfo.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
};

ResolveDrugProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  prescriptionId: PropTypes.string.isRequired,
  drugData: PropTypes.shape({
    unit: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    drug: PropTypes.string.isRequired,
    strength: PropTypes.string.isRequired,
  }).isRequired,
  isEditLabel: PropTypes.bool,
  patientId: PropTypes.string,
  reviewType: PropTypes.string,
};

ResolveDrugProfileModal.defaultProps = {
  isEditLabel: false,
  patientId: '',
  reviewType: '',
};
