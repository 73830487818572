/* eslint-disable import/prefer-default-export */

import { capitalize, first, map, omit, toLower } from 'lodash';

export const transformListLineItems = ({ listLineItems }) => {
  return {
    count: listLineItems.count,
    lineItems: map(listLineItems.results, ({ patient, partner, lineItem }) => {
      const { firstName, lastName } = patient.patientDemographics;

      return {
        ...omit(lineItem, 'item'),
        mpi: patient.mpi,
        patientName: `${lastName}, ${firstName}`,
        partnerName: partner.partnerName,
        itemName: lineItem.item.patientMedication?.name || '',
      };
    }),
  };
};

export const transformBulkLabels = ({ listBulkLabels }) => ({
  count: listBulkLabels.count,
  bulkLabels: map(listBulkLabels.results, ({ patient, ...bulkLabels }) => {
    const { firstName, lastName } = patient.patientDemographics;
    return {
      ...bulkLabels,
      mpi: patient.mpi,
      patientName: `${lastName}, ${firstName}`,
    };
  }),
});

export const transformListOrdersForProductFulfillment = ({ listOrdersForProductFulfillment }) => {
  return {
    count: listOrdersForProductFulfillment.count,
    orders: map(listOrdersForProductFulfillment.orders, ({ patient, partner, ...order }) => {
      const { firstName, lastName } = patient.patientDemographics;
      const { qs1PatientId } = patient.patientIdentity;

      return {
        ...order,
        mpi: patient.mpi,
        patientName: `${lastName}, ${firstName}`,
        qs1Id: `${qs1PatientId}`,
        partnerName: partner.partnerName,
      };
    }),
  };
};

export const transformListRxIntakeRequests = ({ listRxIntakeRequests }) => ({
  count: listRxIntakeRequests.count,
  result: map(
    listRxIntakeRequests.result,
    ({ patient, prescriptions, practice, requestPayload, ...requestDetails }) => {
      const { originalPayload, eScriptMetaData, enrichedPrescriptionPayload } = requestPayload;
      const { firstName, lastName, mpi } = patient;

      return {
        mpi,
        patientName: `${capitalize(lastName)}, ${capitalize(firstName)}`,
        practiceName: requestPayload?.eScriptMetaData?.practiceName,
        medicationName: first(prescriptions).description,
        requestPayload: {
          ...requestPayload,
          enrichedPrescriptionPayload: {
            ...omit(enrichedPrescriptionPayload, 'prescriptions'),
            patient: {
              ...enrichedPrescriptionPayload.patient,
              gender: toLower(enrichedPrescriptionPayload.patient?.gender),
            },
            prescription: first(enrichedPrescriptionPayload.prescriptions),
          },
          documentId: eScriptMetaData?.docuTrackId,
          originalPrescriptionPayload:
            originalPayload?.payloadJson?.eRXs?.message?.body?.newRx || originalPayload,
        },
        ...requestDetails,
      };
    }
  ),
});
