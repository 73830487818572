import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { capitalize, isObject, isString, map, reduce, split } from 'lodash';
import { lighten, useTheme } from '@mui/material/styles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const JsonToDataView = ({ data }) => {
  const theme = useTheme();
  const [openSections, setOpenSections] = useState(null);
  const [showAll, setShowAll] = useState(true);

  useEffect(() => {
    const initializeOpenSections = (payload, togglePrefix = '') =>
      reduce(
        payload,
        (acc, value, key) => {
          const toggleKey = togglePrefix ? `${togglePrefix}-${key}` : key;
          if (isObject(value)) {
            acc[toggleKey] = true;
            Object.assign(acc, initializeOpenSections(value, key));
          } else {
            acc[togglePrefix] = true;
          }
          return acc;
        },
        {}
      );

    setOpenSections(initializeOpenSections(data));
  }, [data]);

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleToggleAll = () => {
    const newState = !showAll;
    setShowAll(newState);
    setOpenSections(
      Object.keys(openSections).reduce((acc, key) => {
        acc[key] = newState;
        return acc;
      }, {})
    );
  };

  const formatKey = (key) => map(split(key, /(?=[A-Z])/), capitalize).join(' ');

  const renderSection = (key, value, spacing, stepperKey) => {
    const toggleKey = stepperKey || key;

    const hasSubObjects =
      isObject(value) && Object.keys(value).some((subKey) => isObject(value[subKey]));

    if (isString(value)) {
      return (
        <Grid key={toggleKey} container sx={{ py: 1 }}>
          <Grid item sx={{ minWidth: 125 }}>
            <Typography
              variant='body1'
              sx={{ color: lighten(theme.palette.text.primary), ml: spacing }}
            >
              {`${formatKey(key)} : `}
            </Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItem: 'center' }}>
            <Typography fontWeight='bold' variant='body1'>
              {value || 'NA'}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    if (isObject(value)) {
      return (
        <Box key={toggleKey} sx={{ m: 0, p: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              py: 0.5,
            }}
            onClick={() => toggleSection(toggleKey)}
          >
            <IconButton size='small'>
              {openSections[toggleKey] ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
            <Typography
              variant='body1'
              sx={{ ml: 1, color: lighten(theme.palette.text.primary, 0.25) }}
            >
              {`${formatKey(key)} `}
            </Typography>
          </Box>
          {openSections[toggleKey] && (
            <Box sx={{ ml: 3, mt: 0.5 }}>
              {Object.keys(value).map((item) =>
                renderSection(item, value[item], hasSubObjects ? 1 : 3, `${key}-${item}`)
              )}
            </Box>
          )}
        </Box>
      );
    }

    return (
      <Typography key={key} variant='body2' sx={{ ml: 3 }}>
        {`${formatKey(key)} : `}
        <Typography component='span' fontWeight='bold'>
          {value?.toString()}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant='body1' sx={{ ml: 1 }}>
          {showAll ? 'Hide All' : 'Show All'}{' '}
          <IconButton
            color='dark'
            id='ExpandAll-button'
            aria-label='toggleButton'
            onClick={handleToggleAll}
          >
            {showAll ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
      </Box>
      {openSections &&
        isObject(data) &&
        Object?.keys(data)?.map((key) => renderSection(key, data[key]))}
    </Box>
  );
};

export default JsonToDataView;

JsonToDataView.propTypes = {
  data: PropTypes.shape({}).isRequired,
};
