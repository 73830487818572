/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listOrdersGql = gql`
  query listOrders(
    $limit: Int = 25
    $offset: Int = 0
    $filter: OrdersFilter
    $sortBy: OrdersVerificationSortBy = { sortKey: needsByDate, sortOrder: DESC }
  ) {
    listOrders(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      orders: orders {
        orderId
        orderStatus
        requestNeedsByDate
        lastUpdated
        createdAt
        waitingOnFill
        totalInOrder
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
        partner {
          partnerName
        }
        lineItems {
          itemName
          needsByDate
          requestedDate
          itemType
          lineItemId
          quantity
          rxNumber
          lineItemStatus
          form
          strength
        }
        partnerAccountId
      }
    }
  }
`;

export const manageProductFulfillmentGql = gql`
  mutation manageProductFulfillmentStatus(
    $mpi: String!
    $orderId: String!
    $lineItemId: String!
    $state: ProductFulfillmentStatus!
    $failureReason: ProductFulfillmentFailureReason
  ) {
    manageProductFulfillmentStatus(
      lineItemId: $lineItemId
      mpi: $mpi
      orderId: $orderId
      state: $state
      failureReason: $failureReason
    ) {
      orderId
      status: orderStatus
      orderCost
      requestId
      requestNeedsByDate
      orderType
      isProcessedExternal
      fulfillingPharmacyId
      shippingAddress {
        line1
        line2
        city
        state
        zip
      }
      partner {
        partnerName
      }
      lineItems {
        itemName
        needsByDate
        requestedDate
        itemType
        lineItemId
        hasPinnedNote
        rxNumber
        strength
        form
        quantity
        lineItemStatus
        lastUpdated
      }
    }
  }
`;

export const createOrderShipmentGql = gql`
  mutation createOrderShipment($mpi: String!, $shipmentType: ShipmentType, $orderIds: [String!]!) {
    createOrderShipment(mpi: $mpi, shipmentType: $shipmentType, orderIds: $orderIds) {
      orderShipmentId
      shipmentData
      shipmentSpType
      shipmentStatus
      spOrderId
      orderIds
    }
  }
`;

export const verifyOrderGql = gql`
  mutation verifyOrder($orderId: String!, $mpi: UUID!) {
    verifyOrder(orderId: $orderId, mpi: $mpi) {
      orderId
      status: orderStatus
      orderCost
      shippingAddress {
        line1
        line2
        city
        state
        zip
      }
      partner {
        partnerName
      }
      lineItems {
        lineItemId
        cost
      }
    }
  }
`;

export const listOrdersForShipmentGql = gql`
  query listOrdersForShipment(
    $limit: Int = 25
    $offset: Int = 0
    $filter: OrdersFilter
    $sortBy: OrdersSortBy = { sortKey: needsByDate, sortOrder: DESC }
  ) {
    listOrdersForShipment(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      orders: orders {
        orderId
        orderStatus
        requestNeedsByDate
        lastUpdated
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const cancelOrderGql = gql`
  mutation cancelOrder(
    $orderId: String!
    $cancelReason: CancelReason!
    $cancelNote: CreateNoteInput!
  ) {
    cancelOrder(orderId: $orderId, cancelReason: $cancelReason, cancelNote: $cancelNote) {
      orderId
    }
  }
`;

export const orderNeedsReviewGql = gql`
  mutation markOrderForExternalProcessing($orderId: String!, $mpi: UUID!) {
    markOrderForExternalProcessing(orderId: $orderId, mpi: $mpi)
  }
`;

export const updateOrderAddressGql = gql`
  mutation updateOrderAddress($orderId: String!, $addressInput: AddPatientAddressInput!) {
    updateOrderAddress(orderId: $orderId, addressInput: $addressInput) {
      __typename
      ... on PatientAddress {
        address {
          addressLine1: line1
          addressLine2: line2
          city
          state
          zip
        }
        nameOnAddress
        tags
        patientAddressId
      }
      ... on Addresses {
        addresses {
          line1
          line2
          city
          state
          zip
        }
      }
    }
  }
`;

export const prescriptionNeedsReviewGql = gql`
  mutation prescriptionNeedsReview($prescriptionUUID: UUID!, $patientId: UUID!) {
    prescriptionNeedsReview(prescriptionUUID: $prescriptionUUID, patientId: $patientId)
  }
`;

export const getOrdersGql = gql`
  query listPomOrders(
    $limit: Int = 25
    $offset: Int = 0
    $filter: PomOrdersFilter
    $sortBy: PomOrdersSortBy = { sortKey: createdAt, sortOrder: DESC }
  ) {
    listPomOrders(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        orderId
        referenceId
        status
        lineItemId
        rxNumber
        patient {
          mpi
          firstName
          lastName
        }
        createdAt
        updatedAt
        partnerName
        partnerAccountId
      }
    }
  }
`;

export const reverifyOrderGql = gql`
  mutation reverifyOrder($orderId: String!, $mpi: UUID!) {
    reverifyOrder(orderId: $orderId, mpi: $mpi) {
      orderId
      status: orderStatus
    }
  }
`;
