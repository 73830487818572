import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import CheckboxField from 'common/forms/CheckboxField';
import AutoCompleteMultipleSelect from 'common/forms/AutoCompleteMultipleSelect';

const DrugProfileForm = ({ disableIsActive, drugProfileId, loading, prescriptionDescription }) => {
  return (
    <>
      <TextField
        id='Create-Drug-Profile-DrugName-input'
        label='Drug Name'
        name='drugName'
        required
        autoFocus
      />

      <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
        <Grid item xl={!drugProfileId ? 11 : 12}>
          <AutoCompleteMultipleSelect
            name='associatedPrescriptionDescriptions'
            label='Associated Prescription Descriptions'
            id='Create-Drug-Profile-AssociatePresDescription-input'
            required
            freeSolo
            loading={loading}
            disabled={isEmpty(prescriptionDescription) && !!drugProfileId}
            options={[]}
            defaultValue={prescriptionDescription || []}
            disableClear={!!drugProfileId}
          />
        </Grid>

        {!drugProfileId && (
          <Grid item xl={1} sx={{ textAlign: 'center' }}>
            <Tooltip
              title='Type and click enter to add multiple prescription description'
              arrow
              placement='bottom-end'
            >
              <InfoIcon />
            </Tooltip>
          </Grid>
        )}
      </Grid>

      <TextField
        id='Create-Drug-Profile-Strength-input'
        label='Strength'
        name='strength'
        required
      />

      <SelectField id='Create-Drug-Profile-unit-dropdown' label='Unit' name='unit' required>
        <MenuItem value='ML'>ML</MenuItem>
        <MenuItem value='MG'>MG</MenuItem>
      </SelectField>

      <SelectField id='Create-Drug-Profile-form-dropdown' label='Form' name='form' required>
        <MenuItem value='VIAL'>VIAL</MenuItem>
        <MenuItem value='TABLET'>TABLET</MenuItem>
        <MenuItem value='N/A'>N/A</MenuItem>
      </SelectField>

      <TextField
        id='Create-Drug-Profile-GenericDescription-input'
        label='Generic Description'
        name='genericDescription'
      />

      <TextField
        id='Create-Drug-Profile-Shipping-Note-input'
        label='Shipping Internal Note'
        name='shipmentInternalNote'
      />

      <TextField
        id='Create-Drug-Profile-manufacturer-input'
        label='Manufacturer'
        name='manufacturer'
        required
      />

      <TextField id='Create-Drug-Profile-ndc-input' label='NDC' name='ndc' required />

      <TextField
        id='Create-Drug-Profile-packageSize-input'
        label='Package Size'
        name='packageSize'
      />

      <TextField id='Create-Drug-Profile-imprint-input' label='Imprint' name='imprint' />

      {!disableIsActive && (
        <CheckboxField
          id='Create-Drug-Profile-isActive-checkbox'
          label='Is Active'
          name='isActive'
        />
      )}
    </>
  );
};

export default DrugProfileForm;

DrugProfileForm.propTypes = {
  loading: PropTypes.bool,
  drugProfileId: PropTypes.string,
  disableIsActive: PropTypes.bool,
  prescriptionDescription: PropTypes.arrayOf(PropTypes.string),
};

DrugProfileForm.defaultProps = {
  loading: false,
  drugProfileId: null,
  disableIsActive: false,
  prescriptionDescription: [],
};
