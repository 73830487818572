import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';

import SigsAutoSuggest from 'common/forms/SigsAutoSuggest';
import { syncSig } from 'store/thunks/workQueueThunks';
import { PrescriptionReviewStatus } from 'enums/prescription.enum';
import { PrescriptionReviewPayloadContext } from 'common/hooks/usePrescriptionReviewPayloadContext';
import { isEmpty } from 'lodash';

const ResolveDirectionsOption = {
  CREATE_SIG_FROM_DIRECTION: 'CREATE_SIG_FROM_DIRECTION',
  ASSOCIATE_WITH_SIG: 'ASSOCIATE_WITH_SIG',
};

const tableCellStye = { borderBottom: 'none', p: 1 };

const ResolvePrescriptionModal = ({
  open,
  onClose,
  drug,
  strength,
  form,
  directions,
  prescriptionId,
  patientId,
  reviewType,
}) => {
  const dispatch = useDispatch();
  const [reviewOption, setReviewOption] = useState(
    ResolveDirectionsOption.CREATE_SIG_FROM_DIRECTION
  );
  const [loading, setLoading] = useState(false);
  const [selectedSig, setSelectedSig] = useState('');
  const [hasOneTimeAssociation, setOneTimeAssociation] = useState(false);
  const { payload, setPayload } = useContext(PrescriptionReviewPayloadContext);
  const isDirectionReview = reviewType === PrescriptionReviewStatus.PRESCRIPTION_DIRECTION_REVIEW;

  const handleChangeRadio = (event) => {
    setReviewOption(event.target.value);
  };

  const handleOneTimeAssociation = () => setOneTimeAssociation(!hasOneTimeAssociation);

  const handleSyncSig = (data) => {
    let sigData;

    if (isDirectionReview) {
      setLoading(true);

      if (reviewOption === ResolveDirectionsOption.CREATE_SIG_FROM_DIRECTION) {
        sigData = { sig: directions };
      }

      if (reviewOption === ResolveDirectionsOption.ASSOCIATE_WITH_SIG) {
        sigData = {
          hasOneTimeAssociation,
          sigId: data.searchExistingSig?.sigId,
          sig: data.searchExistingSig?.sig || data.searchExistingSig,
        };
      }

      dispatch(syncSig({ prescriptionId, ...sigData }))
        .then((isSynced) => {
          if (isSynced) {
            onClose();
            enqueueSnackbar('Prescription Direction resolved!', { variant: 'success' });
          }
        })
        .finally(() => setLoading(false));
    } else {
      const overrideDrugProfilePayload = !isEmpty(payload)
        ? { ...payload, sig: data.searchExistingSig }
        : { patientId, sig: data.searchExistingSig };

      setPayload(overrideDrugProfilePayload);
      onClose();
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Approve Prescription Directions</DialogTitle>
      <Form
        onSubmit={handleSyncSig}
        render={({ handleSubmit, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: 550 }}>
              <Grid>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ ...tableCellStye, p: 0 }}>
                        <Typography sx={{ fontWeight: 600 }}>Drug</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <DrugInfo fieldKey='Name' fieldValue={drug} />
                    <DrugInfo fieldKey='Strength' fieldValue={strength} />
                    <DrugInfo fieldKey='Form' fieldValue={form} />
                    <DrugInfo fieldKey='Directions' fieldValue={directions} />
                  </TableBody>
                </Table>
              </Grid>

              {reviewType === PrescriptionReviewStatus.PRESCRIPTION_DIRECTION_REVIEW && (
                <Grid container direction='row' sx={{ p: 1, pl: 0 }}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='Review-Prescription-option-radioSelect-group'
                      value={reviewOption}
                      onChange={handleChangeRadio}
                      row
                    >
                      <FormControlLabel
                        value={ResolveDirectionsOption.CREATE_SIG_FROM_DIRECTION}
                        control={<Radio />}
                        label='Create Sig From Direction'
                      />
                      <FormControlLabel
                        value={ResolveDirectionsOption.ASSOCIATE_WITH_SIG}
                        control={<Radio />}
                        label='Associate With Sig'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              {(reviewOption === ResolveDirectionsOption.ASSOCIATE_WITH_SIG ||
                reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW) && (
                <SigsAutoSuggest
                  id='Review-Prescription-Existing-Sig-Input'
                  name='searchExistingSig'
                  label='Search Existing'
                  autoFocus
                  maxLength={250}
                  selected={selectedSig}
                  handleSelect={setSelectedSig}
                />
              )}
            </DialogContent>

            <DialogActions>
              <Grid container justifyContent='space-between'>
                <Grid item>
                  {reviewOption === ResolveDirectionsOption.ASSOCIATE_WITH_SIG ? (
                    <Grid container alignItems='center'>
                      <FormControlLabel
                        label='One Time Association'
                        control={<Checkbox onChange={handleOneTimeAssociation} />}
                        sx={{ px: 0, mr: 1 }}
                      />
                      <Tooltip
                        title='This association will apply only to the current case.'
                        arrow
                        color='secondary'
                        placement='top-start'
                        sx={{ px: 0 }}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item>
                  <Button sx={{ mr: 1 }} variant='outlined' color='secondary' onClick={onClose}>
                    Cancel
                  </Button>

                  <LoadingButton
                    disabled={
                      invalid ||
                      (isEmpty(selectedSig) &&
                        reviewType === PrescriptionReviewStatus.PRESCRIPTION_REVIEW)
                    }
                    loading={loading}
                    variant='contained'
                    type='submit'
                  >
                    {isDirectionReview ? 'Save' : 'Confirm'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const DrugInfo = ({ fieldKey, fieldValue }) => (
  <TableRow>
    <TableCell sx={tableCellStye}>{fieldKey}:</TableCell>
    <TableCell sx={tableCellStye}>{fieldValue} </TableCell>
  </TableRow>
);

export default ResolvePrescriptionModal;

DrugInfo.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
};

ResolvePrescriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  drug: PropTypes.string.isRequired,
  directions: PropTypes.string.isRequired,
  strength: PropTypes.string,
  form: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  prescriptionId: PropTypes.string.isRequired,
  patientId: PropTypes.string,
  reviewType: PropTypes.string,
};

ResolvePrescriptionModal.defaultProps = {
  strength: 'NA',
  form: 'NA',
  patientId: '',
  reviewType: '',
};
