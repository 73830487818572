import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalize, includes, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { getOrdersByPage, sortOrdersList } from 'store/thunks/ordersListThunk';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import orderStatus from 'common/constants/orderStatus';
import { useGetPartnerAccountName } from 'common/utils';

const headCells = [
  { id: 'orderId', label: 'Order Id' },
  { id: 'referenceId', label: 'Reference Id' },
  { id: 'partnerName', label: 'Partner Name' },
  { id: 'partnerAccount', label: 'Partner Account' },
  { id: 'patientName', label: 'Patient' },
  { id: 'status', label: 'Status' },
  { id: 'rxNumber', label: 'Rx Number' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'updatedAt', label: 'Updated At' },
];

const sortableColumns = [
  'orderId',
  'referenceId',
  'patientName',
  'rxNumber',
  'createdAt',
  'updatedAt',
  'partnerAccount',
];

const OrdersTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage } = useSelector(({ ordersList }) => ordersList);
  const totalPages = useSelector(({ ordersList }) => Math.ceil(ordersList.count / 25));
  const orders = pages[currentPage];

  const partnerAccountHandler = useGetPartnerAccountName();

  const getPartnerAccountName = (partnerAccountId) => {
    return partnerAccountId && partnerAccountHandler[partnerAccountId]
      ? capitalize(partnerAccountHandler[partnerAccountId].partnerAccountName)
      : 'N/A';
  };

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortOrdersList({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didnt change, don't do anything.
      return;
    }

    dispatch(getOrdersByPage({ page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid component={Paper} sx={{ py: 1, px: 2 }} container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    {!includes(sortableColumns, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(orders, (order) => (
                <TableRow key={order.lineItemId} hover>
                  <TableCell>{order.orderId}</TableCell>
                  <TableCell>{order.referenceId || 'NA'}</TableCell>
                  <TableCell>{capitalize(order.partnerName) || 'N/A'}</TableCell>
                  <TableCell>{getPartnerAccountName(order.partnerAccountId)}</TableCell>
                  <TableCell component='th' scope='row' align='left'>
                    <Link
                      to={`/patients/${order.mpi}`}
                      style={{ color: theme.palette.text.primary }}
                    >
                      {order.patientName}
                    </Link>
                  </TableCell>
                  <TableCell>{orderStatus[order.status]}</TableCell>
                  <TableCell>{order.rxNumber}</TableCell>
                  <TableCell>{format(new Date(order.createdAt), 'MM/dd/yyyy')}</TableCell>
                  <TableCell>{format(new Date(order.updatedAt), 'MM/dd/yyyy')}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(orders) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(orders) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(orders) && isEmpty(orders) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default OrdersTable;
