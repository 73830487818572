/* eslint-disable max-lines */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { lighten, useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { find, isArray, isBoolean, isEmpty, isNumber, map } from 'lodash';

import gender from 'common/constants/gender';

const ItemLabelValue = ({ value, label, color, fontSize, fontWeight, opacity, required }) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ mt: '3px', my: 0.85 }}>
      <Grid item sx={{ width: '30%' }}>
        {label && (
          <Typography
            variant={fontSize || 'body1'}
            sx={{
              ml: 3,
              opacity: 1,
              color: color || lighten(theme.palette.text.primary, opacity),
              fontWeight: fontWeight || 'regular',
            }}
          >
            {`${label} :`}
          </Typography>
        )}
      </Grid>
      <Grid item sx={{ width: '70%' }}>
        {isArray(value) ? (
          <Grid sx={{ ml: 3 }}>
            {map(value, (item, index) => (
              <Typography
                key={`${item}-${index}`}
                sx={{
                  display: 'inline',
                  opacity: 1,
                  ...(required && !item && { color: theme.palette.error.light, fontWeight: 600 }),
                }}
                variant={fontSize || 'body1'}
              >
                {!isEmpty(item) || isBoolean(item) || isNumber(item) ? `${item}, ` : 'N/A, '}
              </Typography>
            ))}
          </Grid>
        ) : (
          <Typography
            sx={{
              ml: 3,
              overflow: 'hidden',
              opacity: 1,
              ...(required &&
                !isBoolean(value) &&
                !value && { color: theme.palette.error.light, fontWeight: 600 }),
            }}
            variant={fontSize || 'body1'}
          >
            {!isEmpty(value) || isBoolean(value) || isNumber(value) ? `${value}` : 'N/A'}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  opacity: PropTypes.number,
  required: PropTypes.bool,
};

ItemLabelValue.defaultProps = {
  label: '',
  value: '',
  color: null,
  fontSize: null,
  fontWeight: null,
  opacity: null,
  required: false,
};

const RxInTakeRequestView = ({ scriptId }) => {
  const { pages, currentPage } = useSelector(({ workQueue }) => workQueue.rxIntakeRequests);
  const scriptDetails = find(pages[currentPage], { scriptId });
  const { enrichedPrescriptionPayload } = scriptDetails.requestPayload;
  const { patient, prescription } = enrichedPrescriptionPayload;
  const { prescribedBy, transferDetails } = prescription;

  return (
    <Grid container sx={{ px: 6, pt: 3 }}>
      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={5}>
          <ItemLabelValue
            required
            label='Partner'
            value={enrichedPrescriptionPayload.partnerUuid}
          />
          <ItemLabelValue
            required
            label='Practice'
            value={enrichedPrescriptionPayload.practiceId}
          />
          <ItemLabelValue
            label='Docutrack Number'
            value={scriptDetails.documentId}
            fontSize='subtitle1'
          />
        </Grid>
      </Grid>

      <Grid item xs={5}>
        <Grid sx={{ pb: 2 }}>
          <Grid container direction='row' alignItems='center' sx={{ ml: 3 }}>
            <Typography variant='h6' fontWeight='bold'>
              Patient
            </Typography>
          </Grid>
          <ItemLabelValue required label='First Name' value={patient?.firstName} />
          <ItemLabelValue required label='Last Name' value={patient?.lastName} />
          <ItemLabelValue required label='Phone Number' value={patient?.phoneNumber} />
          <ItemLabelValue required label='Email' value={patient?.emailAddress} />
          <ItemLabelValue required label='Address' value={patient?.patientAddress?.addressLine1} />
          {patient?.patientAddress?.addressLine2 && (
            <ItemLabelValue value={patient?.patientAddress?.addressLine2} />
          )}
          <ItemLabelValue
            required
            value={[
              patient?.patientAddress?.addressCity,
              patient?.patientAddress?.addressState,
              patient?.patientAddress?.addressZip,
            ]}
            labelHeight='40px'
          />
          <ItemLabelValue required label='DOB' value={patient?.dob} />
          <ItemLabelValue required label='Gender' value={gender[patient?.gender]} />
        </Grid>
        <Grid container direction='row' alignItems='center' sx={{ ml: 3, mt: 2 }}>
          <Typography variant='h6' fontWeight='bold'>
            Prescription
          </Typography>
        </Grid>
        <ItemLabelValue required label='Description' value={prescription?.description} />
        <ItemLabelValue required label='Strength' value={prescription?.strength} />
        <ItemLabelValue required label='Expiration Date' value={prescription?.expirationDate} />
        <ItemLabelValue
          required
          label='Dispense As Written'
          value={prescription?.dispenseAsWritten}
        />
        <ItemLabelValue required label='Directions' value={prescription?.directions} />
        <ItemLabelValue required label='Quantity' value={prescription?.quantity} />
        <ItemLabelValue required label='Needs By Date' value={prescription?.needsByDate} />
        <ItemLabelValue required label='Notes' value={prescription?.notes} />
        <ItemLabelValue required label='Total Refills' value={prescription?.totalRefills} />
      </Grid>

      <Grid
        item
        xs={0.25}
        sx={{ minHeight: '100vh', borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}
      />

      <Grid item xs={5}>
        <Grid container sx={{ ml: 3, mt: 2 }}>
          <Typography variant='h6' fontWeight='bold'>
            Transfer Details
          </Typography>
        </Grid>
        <ItemLabelValue required label='Written Date' value={transferDetails?.writtenDate} />
        <Grid container direction='row' alignItems='center' sx={{ ml: 3, mt: 2 }}>
          <Typography variant='h6' fontWeight='bold'>
            To Pharmacy
          </Typography>
        </Grid>
        <ItemLabelValue required label='Name' value={transferDetails?.toPharmacy?.name} />
        <ItemLabelValue
          required
          label='Phone Number'
          value={transferDetails?.toPharmacy?.phoneNumber}
        />

        <ItemLabelValue
          required
          label='Address'
          value={transferDetails?.toPharmacy?.pharmacyAddress?.addressLine1}
        />
        {transferDetails?.toPharmacy?.pharmacyAddress?.addressLine2 && (
          <ItemLabelValue value={transferDetails?.toPharmacy?.pharmacyAddress?.addressLine2} />
        )}
        <ItemLabelValue
          required
          value={[
            transferDetails?.toPharmacy?.pharmacyAddress?.addressCity,
            transferDetails?.toPharmacy?.pharmacyAddress?.addressState,
            transferDetails?.toPharmacy?.pharmacyAddress?.addressZip,
          ]}
          labelHeight='40px'
        />
        <Grid container direction='row' alignItems='center' sx={{ ml: 3, mt: 2 }}>
          <Typography variant='h6' fontWeight='bold'>
            Prescribed By
          </Typography>
        </Grid>
        <ItemLabelValue required label='NPI' value={prescribedBy?.npi} />
        <ItemLabelValue label='DEA' value={prescribedBy?.dea} />
        <ItemLabelValue label='Title' value={prescribedBy?.title} />
        <ItemLabelValue required label='First Name' value={prescribedBy?.firstName} />
        <ItemLabelValue label='Middle Name' value={prescribedBy?.middleName} />
        <ItemLabelValue required label='Last Name' value={prescribedBy?.lastName} />
        <ItemLabelValue required label='Phone Number' value={prescribedBy?.phoneNumber} />
        <ItemLabelValue required label='Address' value={prescribedBy?.address?.addressLine1} />
        {prescribedBy?.address?.addressLine2 && (
          <ItemLabelValue value={prescribedBy?.address?.addressLine2} />
        )}
        <ItemLabelValue
          required
          value={[
            prescribedBy?.address?.addressCity,
            prescribedBy?.address?.addressState,
            prescribedBy?.address?.addressZip,
          ]}
          labelHeight='40px'
        />
      </Grid>
    </Grid>
  );
};

export default RxInTakeRequestView;

RxInTakeRequestView.propTypes = {
  scriptId: PropTypes.string.isRequired,
};
