import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const PartnerCredsDialog = ({ showModal, onCloseModal, partnerCreds }) => {
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onCloseModal();
    }
  };

  const noteText = `Your partner credentials have been successfully created.\n
  Note: For security reasons, the secret will not be displayed again after you close this dialog.`;

  const handleCopy = () => {
    const credsString = `Client Id: ${partnerCreds.clientId}\nClient Secret: ${partnerCreds.clientSecret}`;
    navigator.clipboard.writeText(credsString);
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleCloseModal}
      aria-describedby='Partner-Credentials-Dialog'
      fullWidth
    >
      <>
        <Grid
          container
          sx={{ px: 3, py: 1.5, bgcolor: 'primary.light' }}
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography component='h2' variant='h6' fontWeight='bold'>
            Partner Credentials
          </Typography>
        </Grid>

        <DialogContent>
          <Grid sx={{ mb: 4 }}>
            <Typography style={{ whiteSpace: 'pre-line' }}>{noteText}</Typography>
          </Grid>

          <Grid
            container
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              padding: '16px',
            }}
          >
            <Grid item xs={11}>
              <Typography>
                Client Id: <strong> {partnerCreds.clientId}</strong>
              </Typography>
              <Typography>
                Client Secret: <strong> {partnerCreds.clientSecret}</strong>
              </Typography>
            </Grid>

            <Grid item xs={1} sx={{ pl: 2 }}>
              <IconButton sx={{ display: 'flex' }} size='small' onClick={() => handleCopy()}>
                <ContentCopyIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='error' onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default PartnerCredsDialog;

PartnerCredsDialog.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  partnerCreds: PropTypes.shape({
    partnerUuid: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    clientSecret: PropTypes.string.isRequired,
  }).isRequired,
};
