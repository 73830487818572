export const OrderStatusFieldKeys = {
  REFERENCE_ID: 'referenceId',
  PATIENT_NAME: 'patientName',
  PARTNER: 'partnerId',
  EXTERNAL_PATIENT_ID: 'externalPatientId',
  ORDER_ID: 'pomOrderId',
  CREATED_AT: 'createdAt',
  STATUS: 'orderStatus',
  EXTERNAL_STATUS: 'externalStatus',
  PARTNER_ACCOUNT: 'partnerAccount',
};

export const OrderType = {
  E_SCRIPT: 'RX_ENTRY',
  OTC_TRANSFER: 'OTC_TRANSFER',
  PRESCRIPTION_TRANSFER: 'PRESCRIPTION_TRANSFER',
};

export const OrderStatus = {
  ALL: 'ALL',
  OPEN: 'OPEN',
  SHIPPED: 'SHIPPED',
  RECEIVED: 'RECEIVED',
  CANCELED: 'CANCELED',
};

export const ExternalStatus = {
  ALL: 'ALL',
  ORDER_SHIPPED_SUCCESS: 'ORDER_SHIPPED_PARTNER_WEBHOOK_SUCCESS',
  ORDER_SHIPPED_FAILURE: 'ORDER_SHIPPED_PARTNER_WEBHOOK_FAILURE',
  ORDER_RECEIVED_SUCCESS: 'ORDER_RECEIVED_PARTNER_WEBHOOK_SUCCESS',
  ORDER_RECEIVED_FAILURE: 'ORDER_RECEIVED_PARTNER_WEBHOOK_FAILURE',
  ORDER_CANCELED_SUCCESS: 'ORDER_CANCELED_PARTNER_WEBHOOK_SUCCESS',
  ORDER_CANCELED_FAILURE: 'ORDER_CANCELED_PARTNER_WEBHOOK_FAILURE',
};

export const ExternalStatusShortCode = {
  ALL: 'All',
  ORDER_SHIPPED_PARTNER_WEBHOOK_SUCCESS: 'Shipped Success',
  ORDER_SHIPPED_PARTNER_WEBHOOK_FAILURE: 'Shipped Failure',
  ORDER_RECEIVED_PARTNER_WEBHOOK_SUCCESS: 'Received Success',
  ORDER_RECEIVED_PARTNER_WEBHOOK_FAILURE: 'Received Failure',
  ORDER_CANCELED_PARTNER_WEBHOOK_SUCCESS: 'Canceled Success',
  ORDER_CANCELED_PARTNER_WEBHOOK_FAILURE: 'Canceled Failure',
};

export const OrderStatusTabs = {
  E_SCRIPT: 'TRANSMIT E-SCRIPT',
};
