import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, values } from 'lodash';

import { filterOrderLineItems } from 'store/thunks/workQueueThunks';

import OrderStatus from 'common/constants/orderStatus';
import { SortKey } from 'enums/common.enum';
import FacilityFilterStatusChip from './FacilityFilterStatusChip';

const OrderLineItemsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.orderLineItems?.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterOrderLineItems({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterOrderLineItems({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      <FacilityFilterStatusChip
        removeFilter={removeFilter}
        selectedFacilities={filter?.fulfillingPharmacyIds || []}
      />

      {filter.orderStatus && (
        <Chip
          label={`Status = ${OrderStatus[filter.orderStatus]}`}
          variant='contained'
          onDelete={removeFilter(SortKey.ORDER_STATUS)}
        />
      )}

      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter(SortKey.PATIENT_NAME)}
        />
      )}

      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter(SortKey.ORDER_ID)}
        />
      )}
      {filter.partnerAccount && (
        <Chip
          label={`Partner Account = ${filter.partnerAccount}`}
          variant='contained'
          onDelete={removeFilter(SortKey.PARTNER_ACCOUNT)}
        />
      )}

      {filter.itemName && (
        <Chip
          label={`Drug Name = ${filter.itemName}`}
          variant='contained'
          onDelete={removeFilter(SortKey.ITEM_NAME)}
        />
      )}

      {filter.partnerName && (
        <Chip
          label={`Partner Name = ${
            find(partners, { partnerName: filter.partnerName }).partnerName
          }`}
          variant='contained'
          onDelete={removeFilter(SortKey.PARTNER_NAME)}
        />
      )}

      {filter.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REQUESTED_DATE)}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.NEEDS_BY_DATE)}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default OrderLineItemsFilterStatusBar;
