import PropTypes from 'prop-types';
import { isEmpty, isObject } from 'lodash';
import React, { forwardRef, useState } from 'react';
import JsonView from '@uiw/react-json-view';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import JsonToDataView from 'common/modules/JsonToDataView';
import NothingFound from 'common/components/NothingFound';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const tabsDetails = {
  rawPayload: 'Raw Payload',
  originalPayload: 'Original Payload',
};

const RxIntakePayloadViewDialog = ({ jsonData, originalPrescriptionPayload, open, closeModel }) => {
  const [tabValue, setTabValue] = useState(tabsDetails.originalPayload);
  const [copied, setCopied] = useState(false);
  const { originalPayload, rawPayload } = tabsDetails;

  const closeDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      closeModel();
    }
  };

  const handleResetCopyJson = () => setCopied(false);

  const handleCopyJson = () => {
    setCopied(true);
    const jsonString = JSON.stringify(jsonData, null, 2);
    navigator.clipboard.writeText(jsonString);
  };

  const handleChange = (_, newValue) => setTabValue(newValue);

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={!!open}
      onClose={closeDialog}
      TransitionComponent={Transition}
    >
      <TabContext value={tabValue}>
        <Grid container sx={{ px: 2, pt: 2 }}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Typography variant='h5' sx={{ m: 2 }} gutterBottom>
                Original Payload
              </Typography>
            </Grid>

            <Grid item>
              {copied ? (
                <Tooltip title='Copied'>
                  <IconButton
                    sx={{ display: 'flex' }}
                    size='small'
                    onClick={() => handleResetCopyJson()}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <IconButton sx={{ display: 'flex' }} size='small' onClick={() => handleCopyJson()}>
                  <ContentCopyIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <TabList onChange={handleChange} aria-label='RxIntakePayloadView Tabs'>
              <Tab label={originalPayload} value={originalPayload} />
              <Tab label={rawPayload} value={rawPayload} />
            </TabList>
          </Box>
        </Grid>

        <DialogContent sx={{ minHeight: 450 }}>
          {isEmpty(jsonData) || isEmpty(originalPrescriptionPayload) ? (
            <NothingFound />
          ) : (
            <Box sx={{ height: 450 }}>
              <TabPanel value={originalPayload} label={originalPayload} sx={{ p: 0, m: 0 }}>
                <Grid sx={{ px: 1 }}>
                  <JsonToDataView data={originalPrescriptionPayload} />
                </Grid>
              </TabPanel>
              <TabPanel value={rawPayload} label={rawPayload} sx={{ p: 0, m: 0 }}>
                <Grid sx={{ px: 2 }}>
                  <JsonView
                    collapsed={250}
                    value={isObject(jsonData) ? jsonData : { jsonData }}
                    enableClipboard={false}
                  />
                </Grid>
              </TabPanel>
            </Box>
          )}
        </DialogContent>
      </TabContext>

      <DialogActions>
        <Button onClick={closeDialog} size='small' variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RxIntakePayloadViewDialog;

RxIntakePayloadViewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
  jsonData: PropTypes.shape({}),
  originalPrescriptionPayload: PropTypes.shape({}),
};

RxIntakePayloadViewDialog.defaultProps = {
  jsonData: null,
  originalPrescriptionPayload: null,
};
