import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { map } from 'lodash';

import { SortKey } from 'enums/common.enum';
import OrderStatus from 'enums/order.enum';

import SelectField from 'common/forms/SelectField';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterOrderLineItems } from 'store/thunks/workQueueThunks';

import FacilityFilterField from './FacilityFilterField';

const OrderLineItemsFilterButton = () => {
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const filter = useSelector(({ workQueue: { orderLineItems } }) => ({
    ...orderLineItems.filter,
    requestedDate:
      orderLineItems.filter?.requestedDate && new Date(orderLineItems.filter.requestedDate),
    needsByDate: orderLineItems.filter?.needsByDate && new Date(orderLineItems.filter.needsByDate),
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-OrderFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterOrderLineItems({
        filter: {
          ...formData,
          requestedDate: formData.requestedDate && formData.requestedDate.toISOString(),
          needsByDate: formData.needsByDate && formData.needsByDate.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />

                  <TextField
                    label='Patient Name'
                    name={SortKey.PATIENT_NAME}
                    id='WorkQueue-OrderFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Partner Name'
                    name={SortKey.PARTNER_NAME}
                    id='WorkQueue-OrderFilterButton-partnerUuid-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(partners, ({ partnerName }) => (
                      <MenuItem value={partnerName} key={partnerName}>
                        {partnerName}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <TextField
                    label='Order Id'
                    name={SortKey.ORDER_ID}
                    id='WorkQueue-OrderFilterButton-orderId-input'
                  />

                  <TextField
                    label='Partner Account'
                    name={SortKey.PARTNER_ACCOUNT}
                    id='WorkQueue-OrderFilterButton-partnerAccount-input'
                  />

                  <TextField
                    label='Drug Name'
                    name={SortKey.ITEM_NAME}
                    id='WorkQueue-OrderFilterButton-itemName-input'
                  />

                  <DateField
                    label='Requested Date'
                    name={SortKey.REQUESTED_DATE}
                    id='WorkQueue-OrderFilterButton-requestedDate-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Needs By Date'
                    name={SortKey.NEEDS_BY_DATE}
                    id='WorkQueue-OrderFilterButton-needsByDate-dateField'
                  />

                  <SelectField
                    label='Status'
                    name={SortKey.ORDER_STATUS}
                    id='WorkQueue-OrderFilterButton-orderStatus-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value={OrderStatus.OPEN}>Open</MenuItem>
                    <MenuItem value={OrderStatus.READY_FOR_SHIPMENT}>Ready for Shipment</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default OrderLineItemsFilterButton;
