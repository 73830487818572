import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { getOrdersByPage } from 'store/thunks/ordersListThunk';
import { getPartners } from 'store/thunks/partnerThunks';
import OrdersFilterButton from './components/ordersFilterButton';
import OrdersTable from './components/ordersTable';
import OrderFilterStatusBar from './components/OrderFilterStatusBar';

const OrdersList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Orders - SmartOS';
  }, []);

  useEffect(() => {
    dispatch(getOrdersByPage({ page: 1 }));
    dispatch(getPartners());
  }, [dispatch]);

  return (
    <Grid container sx={{ px: 3, pt: 2 }} direction='column'>
      <Grid item container justifyContent='space-between'>
        <Typography component='h1' variant='h5'>
          Orders
        </Typography>

        <Grid item>
          <OrdersFilterButton />
        </Grid>
      </Grid>

      <Grid item sx={{ minHeight: 40, pb: 2, pt: 2 }} container alignItems='center'>
        <OrderFilterStatusBar />
      </Grid>

      <Grid item>
        <OrdersTable />
      </Grid>
    </Grid>
  );
};

export default OrdersList;
