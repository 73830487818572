/* eslint-disable max-lines */
import { gql } from 'graphql-request';

export const listSigsGql = gql`
  query listSigs($searchText: String!) {
    listSigs(searchText: $searchText) {
      sigId
      sig
    }
  }
`;

export const syncSigGql = gql`
  mutation syncSig(
    $sig: String!
    $sigId: UUID
    $prescriptionId: UUID!
    $hasOneTimeAssociation: Boolean
  ) {
    syncSig(
      sig: $sig
      sigId: $sigId
      prescriptionId: $prescriptionId
      hasOneTimeAssociation: $hasOneTimeAssociation
    )
  }
`;

export const listPrescriptionForReviewGql = gql`
  query listPrescriptionForReview(
    $limit: Int = 25
    $offset: Int = 0
    $filter: PrescriptionReviewFilter
    $sortBy: PrescriptionReviewSortBy
  ) {
    listPrescriptionForReview(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result: results {
        orderId
        patient {
          patientId
          patientDemographics {
            firstName
            lastName
            middleName
            dob
            mpi
            gender
          }
          patientContact {
            phoneNumbers {
              phoneNumber
              tags
            }
            addresses {
              address {
                line1
                line2
                city
                state
                zip
              }
              tags
            }
            emails {
              email
              tags
            }
          }
          patientIdentity {
            qs1PatientId
          }
        }
        interactionData {
          status
          existingPrescriptions {
            ...PrescriptionFields
          }
        }
        prescription {
          ...PrescriptionFields
        }
        lineItem {
          needsByDate
          requestedDate
        }
      }
    }
  }

  fragment PrescriptionFields on PatientPrescription {
    rxNumber
    drug
    orderId
    prescriber
    prescribedDrugName
    generic
    quantityPrescribed
    dispensedQuantity
    writtenDate
    originalRx
    expirationDate
    isActive
    patientName
    prescriberPhone
    refillsRemaining
    sig
    drugUnit
    lastFillDate
    nextRefill
    daw
    dateOrdered
    prescriptionUUID
    strength
    form
    documentId
    request {
      medication {
        instructions
        ndc
        prescriber {
          firstName
          middleName
          lastName
          title
          npi
          addressLine1
          addressLine2
          city
          state
          zip
          phone
          fax
        }
      }
    }
    practice {
      practiceId
      practiceName
      prescriberName
      prescriberNpi
      electronicallySignedBy
      supervisor
      prescriberDea
      orderingAgent
      phone
      fax
      line1
      line2
      city
      state
      zip
    }
    prescriptionOverride {
      prescriptionUUID
      drugProfile {
        drugName
        strength
        unit
        genericDrugName
        manufacturer
        ndc
        form
      }
      sig {
        sigText
      }
      prescriptionDetails {
        prescriberName
        refills
        quantity
      }
      patientId
    }
    originatingPharmacy {
      ...PharmacyFields
    }
    transferPharmacies {
      ...PharmacyFields
    }
  }

  fragment PharmacyFields on Pharmacy {
    pharmacyAddress {
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
    }
    phoneNumber
    dea
    name
    npi
    pharmacistName
    transferDate
  }
`;

export const resolveDuplicatePrescriptionGql = gql`
  mutation resolveDuplicatePrescriptionReview(
    $prescriptionId: UUID!
    $orderId: String!
    $isDuplicate: Boolean!
  ) {
    resolveDuplicatePrescriptionReview(
      prescriptionId: $prescriptionId
      orderId: $orderId
      isDuplicate: $isDuplicate
    )
  }
`;

export const resolveDrugProfileGql = gql`
  mutation resolveDrugProfile($input: UpsertDrugProfileInput!, $prescriptionId: UUID!) {
    resolveDrugProfile(input: $input, prescriptionId: $prescriptionId)
  }
`;

export const overridePrescriptionGql = gql`
  mutation overridePrescription($input: OverridePrescriptionPayloadInput) {
    overridePrescription(input: $input) {
      prescriptionUUID
      drugProfile {
        drugName
        strength
        unit
        genericDrugName
        manufacturer
        ndc
        form
      }
      sig {
        sigText
      }
      prescriptionDetails {
        prescriberName
        refills
        quantity
      }
      patientId
    }
  }
`;

export const getPrescriptionOverrideAuditsGql = gql`
  query listPrescriptionOverrideAudits($prescriptionUUID: UUID!) {
    listPrescriptionOverrideAudits(prescriptionUUID: $prescriptionUUID) {
      drugProfile {
        ...CommonFields
      }
      sig {
        ...CommonFields
      }
      prescriptionDetails {
        prescriberName {
          ...CommonFields
        }
        refills {
          ...CommonFields
        }
        quantity {
          ...CommonFields
        }
      }
    }
  }

  fragment CommonFields on FieldEditHistory {
    editedTimestamp
    editedDetails {
      fieldName
    }
    user {
      userId
      firstName
      lastName
    }
  }
`;
