import { map } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import { validateMinLength } from 'common/forms/formValidations';
import {
  ExternalStatus,
  ExternalStatusShortCode,
  OrderStatus,
  OrderStatusFieldKeys,
  OrderType,
} from 'enums/requestStatus.enum';
import { updatePrescriptionOrdersFilter } from 'store/thunks/orderStatusThunks';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import FacilityFilterField from 'modules/WorkQueue/components/FacilityFilterField';

const EScriptOrdersFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ orderStatus: { eScriptOrders } }) => ({
    ...eScriptOrders.filter,
    orderStatus: eScriptOrders.filter?.orderStatus
      ? eScriptOrders.filter?.orderStatus
      : OrderStatus.ALL,
    externalStatus: eScriptOrders.filter?.externalStatus
      ? eScriptOrders.filter?.externalStatus
      : ExternalStatus.ALL,
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'OrderStatusFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      updatePrescriptionOrdersFilter({
        filter: {
          ...formData,
          orderType: OrderType.E_SCRIPT,
          orderStatus: formData?.orderStatus === OrderStatus.ALL ? undefined : formData.orderStatus,
          externalStatus:
            formData?.externalStatus === ExternalStatus.ALL ? undefined : formData.externalStatus,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 350 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />

                  <TextField
                    label='Reference ID'
                    name={OrderStatusFieldKeys.REFERENCE_ID}
                    id='OrderFilterButton-referenceId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Patient Name'
                    name={OrderStatusFieldKeys.PATIENT_NAME}
                    id='OrderFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='External Patient ID'
                    name={OrderStatusFieldKeys.EXTERNAL_PATIENT_ID}
                    id='OrderFilterButton-externalPatientId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Partner Account'
                    name={OrderStatusFieldKeys.PARTNER_ACCOUNT}
                    id='OrderFilterButton-partnerAccount-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Order ID'
                    name={OrderStatusFieldKeys.ORDER_ID}
                    id='OrderFilterButton-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Order Status'
                    name={OrderStatusFieldKeys.STATUS}
                    id='OrderFilterButton-status-dropdown'
                  >
                    <MenuItem value={OrderStatus.ALL}>All</MenuItem>
                    <MenuItem value={OrderStatus.OPEN}>Open</MenuItem>
                    <MenuItem value={OrderStatus.RECEIVED}>Received</MenuItem>
                    <MenuItem value={OrderStatus.SHIPPED}>Shipped</MenuItem>
                    <MenuItem value={OrderStatus.CANCELED}>Canceled</MenuItem>
                  </SelectField>

                  <SelectField
                    label='External Status'
                    name={OrderStatusFieldKeys.EXTERNAL_STATUS}
                    id='OrderFilterButton-externalStatus-dropdown'
                  >
                    {map(ExternalStatusShortCode, (key, value) => (
                      <MenuItem key={key} value={value}>
                        {key}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default EScriptOrdersFilterButton;
