/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listApiOrdersStatusGql = gql`
  query listApiOrdersStatus(
    $limit: Int = 25
    $offset: Int = 0
    $filter: ListApiOrdersFilter
    $sortBy: ListApiOrdersSortBy = { sortKey: createdAt, sortOrder: DESC }
  ) {
    listApiOrdersStatus(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        orderType
        referenceId
        pomOrderId
        patient {
          mpi
          firstName
          lastName
        }
        partner {
          partnerName
          partnerUuid
        }
        externalPatientId
        createdAt
        status
        requestId
        partnerAccountId
      }
    }
  }
`;

export const getRequestByReferenceIdGql = gql`
  query getPomRequestByReferenceId($referenceId: String!, $requestId: String!) {
    getPomRequestByReferenceId(referenceId: $referenceId, requestId: $requestId) {
      orderType
      referenceId
      pomOrderId
      externalPatientId
      status
      createdAt
      prescription {
        description
        strength
        expirationDate
        dispenseAsWritten
        directions
        quantity
        totalRefills
        needsByDate
        notes
        originatingPharmacy {
          ...PharmacyFields
        }
        transferPharmacies {
          ...PharmacyFields
        }
        prescribedBy {
          npi
          firstName
          lastName
          middleName
          phoneNumber
          address {
            addressLine1
            addressLine2
            addressCity
            addressState
            addressZip
          }
          dea
          deaState
          emailAddress
          title
          verificationStatus
          name
        }
      }
      patient {
        mpi
        firstName
        lastName
        email
        mobilePhone
        gender
        dob
      }
      partner {
        partnerName
      }
      practice {
        practiceId
        practiceName
        prescriberName
        phone
        line1
        line2
        city
        state
        zip
      }
      events
      errors
    }
  }

  fragment PharmacyFields on Pharmacy {
    pharmacyAddress {
      addressLine1
      addressLine2
      addressCity
      addressState
      addressZip
    }
    phoneNumber
    dea
    name
    npi
    pharmacistName
    transferDate
  }
`;
