import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, isEmpty, isNil, keyBy, map, toLower } from 'lodash';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { SortKey, SortOrder } from 'enums/common.enum';
import {
  listRxIntakeRequestsByPage,
  updateRxIntakeRequestSort,
} from 'store/thunks/workQueueThunks';
import { useGetPartnerAccountName } from 'common/utils';

import RxInTakeRequestsOptions from './RxInTakeRequestsOptions';

const headCells = [
  {
    id: SortKey.PATIENT_NAME,
    label: 'Patient Name',
    sortable: true,
  },
  {
    id: SortKey.MEDICATION_NAME,
    label: 'Drug Name',
    sortable: true,
  },
  {
    id: SortKey.PARTNER_NAME,
    label: 'Partner Name',
    sortable: false,
  },
  {
    id: SortKey.PARTNER_ACCOUNT,
    label: 'Partner Account',
    sortable: true,
  },
  {
    id: SortKey.REQUEST_STATUS,
    label: 'Status',
    sortable: true,
  },
  {
    id: SortKey.PRACTICE_NAME,
    label: 'Practice',
    sortable: true,
  },
  {
    id: SortKey.SCRIPT_ID,
    label: 'Script ID',
    sortable: true,
  },
  {
    id: SortKey.DOCUTRACK_ID,
    label: 'Docutrack Number',
    sortable: true,
  },
  {
    id: SortKey.CREATED_AT,
    label: 'Created At',
    sortable: true,
  },
];

const RxInTakeRequestsTable = () => {
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage, totalPages } = useSelector(({ workQueue }) => ({
    ...workQueue.rxIntakeRequests,
    totalPages: Math.ceil(workQueue.rxIntakeRequests.count / 25),
  }));
  const rxIntakeRequests = pages[currentPage];

  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const partnerIdToName = keyBy(partners, 'partnerUuid');

  const partnerAccountHandler = useGetPartnerAccountName();

  const getPartnerAccountName = (partnerAccountId) => {
    return partnerAccountId && partnerAccountHandler[partnerAccountId]
      ? capitalize(partnerAccountHandler[partnerAccountId].partnerAccountName)
      : 'N/A';
  };

  const getPartnerName = (partnerId) => {
    return partnerId && partnerIdToName[partnerId]?.partnerName
      ? capitalize(partnerIdToName[partnerId].partnerName)
      : 'N/A';
  };

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      updateRxIntakeRequestSort({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page did not change, do not do anything.
      return;
    }

    dispatch(listRxIntakeRequestsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listRxIntakeRequestsByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        data-head-property={headCell.id}
                        onClick={handleSort}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(rxIntakeRequests, (request, index) => (
                <TableRow key={`${request.scriptId}-${index}`} hover>
                  <TableCell component='th' scope='row' align='left'>
                    {request.patientName}
                  </TableCell>
                  <TableCell sx={{ width: 350 }}>{capitalize(request.medicationName)}</TableCell>
                  <TableCell>{getPartnerName(request.partnerId)}</TableCell>
                  <TableCell>{getPartnerAccountName(request.partnerAccountId)}</TableCell>
                  <TableCell>{capitalize(request.status)}</TableCell>
                  <TableCell>{capitalize(request.practiceName)}</TableCell>
                  <TableCell>{capitalize(request.scriptId)}</TableCell>
                  <TableCell>
                    {request?.requestPayload?.eScriptMetaData?.docuTrackId || 'N/A'}
                  </TableCell>
                  <TableCell>
                    {format(utcToZonedTime(new Date(request.createdAt), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>
                    <RxInTakeRequestsOptions
                      scriptId={request.scriptId}
                      scriptStatus={request.status}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(rxIntakeRequests) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(rxIntakeRequests) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(rxIntakeRequests) && isEmpty(rxIntakeRequests) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default RxInTakeRequestsTable;
