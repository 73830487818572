import { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { map } from 'lodash';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterEVisitRequests } from 'store/thunks/workQueueThunks';
import SelectField from 'common/forms/SelectField';
import { awaitingRequestStatus } from 'enums/request.enum';

import FacilityFilterField from './FacilityFilterField';

const EVisitRequestsFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ workQueue: { eVisitRequests } }) => ({
    ...eVisitRequests.filter,
    requestedDate:
      eVisitRequests.filter?.requestedDate && new Date(eVisitRequests.filter.requestedDate),
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-EVisitRequestsFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterEVisitRequests({
        filter: {
          ...formData,
          requestedDate: formData.requestedDate && formData.requestedDate.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />

                  <TextField
                    label='Patient Name'
                    name='patientName'
                    id='WorkQueue-EVisitRequestsFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Drug Name'
                    name='bundleLabel'
                    id='WorkQueue-EVisitRequestsFilterButton-bundleLabel-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Provider Name'
                    name='provider'
                    id='WorkQueue-EVisitRequestsFilterButton-provider-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Requested Date'
                    name='requestedDate'
                    id='WorkQueue-EVisitRequestsFilterButton-requestedDate-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Status'
                    name='status'
                    id='WorkQueue-EVisitRequestsFilterButton-status-input'
                  >
                    {map(awaitingRequestStatus, (status, key) => (
                      <MenuItem value={key} key={status}>
                        {status}
                      </MenuItem>
                    ))}
                    <MenuItem value={undefined} key='ALL'>
                      All
                    </MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default EVisitRequestsFilterButton;
