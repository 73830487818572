import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, map, omit, orderBy, some } from 'lodash';
import { useState } from 'react';
import { normalizePhoneNumber } from 'common/utils';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import CheckIcon from '@mui/icons-material/Check';

import VerifiedTag from 'common/modules/VerifiedTag';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { VerificationStatus } from 'enums/provider.enum';

import { ProviderVerificationStatus } from 'enums/common.enum';
import PatientProviderOptions from './PatientProviderOptions';

const ProviderLabelValue = ({ value, label, md, lg }) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container direction='column' item xs={12} sm={6} md={md} lg={lg}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

ProviderLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
};
ProviderLabelValue.defaultProps = {
  value: '',
  md: 2,
  lg: 2,
};

const ProviderRow = ({ provider, mpi }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((show) => !show);
  const hasNoDetails = !some(
    omit(provider, [
      'patientProviderId',
      'firstName',
      'lastName',
      'phone',
      'email',
      'isActive',
      'npi',
    ])
  );

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset', bgcolor: showDetails ? '#0000000a' : 'inherit' } }}
        hover
      >
        <TableCell>
          <IconButton
            aria-label={`Toggle Details ${provider.firstName} ${provider.lastName}`}
            size='small'
            onClick={toggleDetails}
            disabled={hasNoDetails}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' align='left'>
          {provider.firstName} {provider.lastName}
        </TableCell>
        <TableCell align='center'>{normalizePhoneNumber(provider.phone)}</TableCell>
        <TableCell align='center'>{provider.email}</TableCell>
        <TableCell align='center'>
          <Grid container justifyContent='center' alignItems='center'>
            {provider.npi}
            <VerifiedTag
              status={
                ProviderVerificationStatus[provider.verificationStatus] ||
                VerificationStatus.UNVERIFIED
              }
            />
          </Grid>
        </TableCell>
        <TableCell align='center'>
          {provider.isActive && (
            <>
              <CheckIcon />
              <Typography sx={visuallyHidden}>Yes</Typography>
            </>
          )}
        </TableCell>
        <TableCell align='center'>
          <PatientProviderOptions
            isActive={provider.isActive}
            mpi={mpi}
            patientProviderId={provider.patientProviderId}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderTop: 'unset' } }}>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showDetails} timeout='auto' unmountOnExit>
            <Grid container sx={{ px: 10, pt: 2, pb: 6 }} gap={2}>
              <Grid container spacing={2}>
                <ProviderLabelValue label='Fax' value={normalizePhoneNumber(provider.fax)} />
              </Grid>

              <Grid container spacing={2}>
                <ProviderLabelValue
                  label='Address Line 1'
                  value={provider.addressLine1}
                  md={4}
                  lg={3}
                />
                <ProviderLabelValue label='Address Line 2' value={provider.addressLine2} />
                <ProviderLabelValue label='City' value={provider.city} />
                <ProviderLabelValue label='State' value={provider.state} />
                <ProviderLabelValue label='Zip' value={provider.zip} />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ProviderRow.propTypes = {
  provider: PropTypes.shape({
    patientProviderId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    npi: PropTypes.string,
    email: PropTypes.string,
    isActive: PropTypes.bool,
    verificationStatus: PropTypes.string,
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

const PatientProvidersTable = ({ mpi }) => {
  const providers = useSelector(({ patient }) => patient[mpi]?.providers);
  const sortedProviders = orderBy(providers, ['isActive'], ['desc']);

  return (
    <>
      <TableContainer>
        <Table aria-label='Medications List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Provider Name</TableCell>
              <TableCell align='center'>Phone</TableCell>
              <TableCell align='center'>Email</TableCell>
              <TableCell align='center'>NPI</TableCell>
              <TableCell align='center'>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(sortedProviders, (provider) => (
              <ProviderRow key={provider.patientProviderId} provider={provider} mpi={mpi} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(providers) && <LoadingModule height='25vh' />}

      {!isNil(providers) && isEmpty(providers) && <NothingFound height='25vh' />}
    </>
  );
};

PatientProvidersTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientProvidersTable;
